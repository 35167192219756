import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Outlet, Link } from "react-router-dom"
import usePersist from "../../hooks/usePersist"
import { useRefreshMutation } from "./authApiSlice"
import { selectCurrentToken } from "./authSlice"
import PulseLoader from "react-spinners/PulseLoader"


const PersistLogin = () => {
    const [persist] = usePersist()
    const token = useSelector(selectCurrentToken)
    const effectRan = useRef(false) // for handling react strict mode in react 18

    const [trueSuccess, setTrueSuccess] = useState(false)

    const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
        useRefreshMutation()

    useEffect(() => {
        // the first time useEffect runs it will be false, but in strict mode what happens to every component is they mount, unmount and remount
        // hence useEffect runs twice when in strict mode
        if (
            effectRan.current === true ||
            process.env.NODE_ENV !== "development" // React 18 Strict mode only happens in development
        ) {
            const verifyRefreshToken = async () => {
                console.log("verifying refresh token")
                try {
                    // const response =
                    await refresh()
                    // const { accessToken } = response.data
                    setTrueSuccess(true)
                } catch (err) {
                    console.log(err)
                }
            }

            if (!token && persist) verifyRefreshToken()
        }

        return () => (effectRan.current = true)

        // eslint-disable-next-line
    }, [])

    let content
    if (!persist) {
        // persist: no
        console.log("no persist")
        content = <Outlet />
    } else if (isLoading) {
        //persist: yes, token: no
        console.log("loading")
        content = <PulseLoader color="#FFF"/>
    } else if (isError) {
        //persist: yes, token: no
        console.log("error")
        content = (
            <p className="errmsg">
                {`${error?.data?.message} - `}
                <Link to="/login">Please login again</Link>
            </p>
        )
    } else if (isSuccess && trueSuccess) {
        //persist: yes, token: yes
        console.log("success")
        content = <Outlet />
    } else if (token && isUninitialized) {
        //persist: yes, token: yes
        console.log("token and uninit")
        console.log(isUninitialized)
        content = <Outlet />
    }
    if (!persist) {
        console.log("No persist")
        content = <Outlet />
    } else if (isLoading) {
    }

    return content
}

export default PersistLogin
